//@flow

import type { Element } from "React";
import { useContext, useEffect, useState } from "react";
import HelpPanel from "../HelpPanel";
import OrientationContext, { PORTRAIT } from "../../components/providers/OrientationProvider.jsx";
import GameSettingsContext from "src/components/providers/GameSettingsProvider.jsx";
import ProgressPills from "../ProgressPills";
import { ReactComponent as Next } from "../../icons/next.svg";
import { ReactComponent as Previous } from "../../icons/previous.svg";

import type { GameState, MoveDirection } from "src/core/types.js";
import { reset } from "src/core/baoside.js";
import { KISWAHILI } from "src/core/baogame.js";
import {
  logPageView,
  logTutorialComplete,
  logTutorialNavigatedBackward,
  logTutorialNavigatedForward,
} from "../../hooks/use-analytics";
import { Helmet } from "react-helmet";

type Props = {
  path: string
};

type Help = {
  instruction: string,
  gameState: GameState,
  moveIndex: number,
  moveDirection: MoveDirection,
  highlightedHoles?: { "0": number[], "1": number[] }
};
const helpData: Help[] = [
  {
    instruction: `This is Bawo (aka Bao). A board game played in some parts of sub-saharan Africa. 
    It is a two player game - often with spectators. The board has 32 pits divided into two equal sides each having 16 pits.
    The game starts with two seeds in each pit. This is the simplest version of the game.`,
    gameState: [reset(), reset()],
    moveIndex: -1,
    moveDirection: 1
  },
  {
    instruction: `If you see a ▶ symbol above the board, it means the instruction has some interactive content. Go ahead and click it. This sequence shows a tentative starting move by a player. You might also notice some pits slowly flashing. The flashing pits designate what are valid moves in the current game state.`,
    gameState: [reset(), reset()],
    moveIndex: 8,
    moveDirection: -1
  },
  {
    instruction: `A player begins a turn by picking all the seeds from any pit that has at least two seeds and sows them in a clockwise or anti-clockwise direction.
    Here, the player is sowing in a clockwise direction.`,
    gameState: [reset(), [0, 1, 0, 1, 8, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
    moveIndex: 4,
    moveDirection: 1
  },
  {
    instruction: "And here, the player is sowing in an anti-clockwise direction.",
    gameState: [reset(), [0, 1, 0, 1, 8, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
    moveIndex: 4,
    moveDirection: -1
  },
  {
    instruction: `A *marker* pit is a pit of the inner row that faces a non-empty opponent's pit. Marker pits are highlighted.
    If the last seed in a sowing round lands on a marker pit, capturing occurs and the turn is called an *mtaji* turn.
    Notice that sowing of the captured pieces continues in the same direction as the initial sowing.`,
    gameState: [reset(), [2, 0, 2, 0, 2, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
    moveIndex: 0,
    moveDirection: 1,
    highlightedHoles: { "1": [0, 2, 4, 6], "0": [] }
  },
  {
    instruction: `Each of the first two or last two pits of a player's inner row is called a *kimbi*. When capturing occurs in a kimbi, sowing continues from the closest *kichwa*. A *kichwa* is the first or last pit in a player's inner row.
    If a right *kimbi* is captured, then sowing continues counter-clockwise from the inner right *kichwa*. If a left *kimbi* is captured, sowing continues clockwise from the left *kichwa*. The opponent's *kimbis* are highlighted.`,
    gameState: [reset(), reset()],
    moveIndex: 2,
    moveDirection: -1,
    highlightedHoles: { "0": [0, 1, 6, 7], "1": [] }
  },
  {
    instruction: `If a player has a capturing move, then that move must be played. If there are multiple capturing moves, the player is free to choose which one to make.`,
    gameState: [
      [7, 0, 3, 0, 0, 0, 0, 0, 0, 0, 9, 0, 0, 2, 1, 1],
      [0, 2, 0, 2, 5, 0, 4, 8, 0, 2, 1, 2, 3, 0, 0, 0, 0]
    ],
    moveIndex: 9,
    moveDirection: -1
  },
  {
    instruction: `If the first sowing does not lead to a capture, then no capture can occur in the turn.
    A non-capturing turn is called a takata turn.`,
    gameState: [
      [0, 0, 3, 0, 0, 0, 0, 3, 0, 0, 9, 0, 0, 2, 1, 1],
      [0, 2, 0, 2, 5, 0, 4, 8, 0, 1, 1, 12, 3, 0, 0, 0, 0]
    ],
    moveIndex: 1,
    moveDirection: 1
  },
  {
    instruction: `The game ends if the first row is empty or a player is unable to make a move because they only have one seed in each hole.
    Go ahead and [Try a game](/game)`,
    gameState: [
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 3, 1, 4, 4, 3, 2]
    ],
    moveIndex: -1,
    moveDirection: 1
  }
];

const HelpSection = (props: Props): Element<"div"> => {
  const [helpIndex, setHelpIndex] = useState(0);
  const help = helpData[helpIndex];

  const onNextClick = (event) => {
    event.preventDefault();
    const nextIndex = Math.min(helpIndex + 1, helpData.length - 1)
    setHelpIndex(nextIndex);
    logTutorialNavigatedForward(nextIndex);
    if (nextIndex === helpData.length - 1) {
      logTutorialComplete();
    }
  };

  const onPreviousClick = (event) => {
    event.preventDefault();
    const nextIndex = Math.max(helpIndex - 1, 0)
    setHelpIndex(nextIndex);
    logTutorialNavigatedBackward(nextIndex);
  };

  const orientation = useContext(OrientationContext);
  const { gameType } = useContext(GameSettingsContext);

  const navButtonStyle = ({ gridUnit, colors }) => ({
    border: "none",
    background: colors.grey9,
    height: gridUnit * 9,
    width: gridUnit * 9,
    padding: 0,
    borderRadius: "100%",
    cursor: 'pointer',
    "&:hover": {
      background: colors.grey7,
    }
  });

  const buttonWrapperStyles = {
    display: "flex",
    justifyContent: "space-between"
  };

  const xOffset = gameType === KISWAHILI ? 70 : 0;

  useEffect(() => {
    logPageView(
      "Bao | How to play",
      window.location.href
    )
  })
  return (
    <div
      css={({ boardHeight, boardWidth }) => ({
        width: orientation === PORTRAIT ? boardHeight + xOffset : boardWidth,
        height: orientation === PORTRAIT ? boardWidth : boardHeight,
        background: "white",
        transformOrigin: "top left",
        transform: orientation === PORTRAIT ? `translate(0, ${boardHeight + xOffset}px) rotate(-90deg)` : "rotate(0)"
      })}
    >
      <Helmet>
        <title>Bawo | How to Play</title>
        <meta name="description" content="How to play Bao" />
      </Helmet>
      <div css={buttonWrapperStyles}>
        <button disabled={helpIndex === 0} css={navButtonStyle} data-testid="previous">
          <Previous onClick={onPreviousClick} />
        </button>
        <ProgressPills size={helpData.length} activeIndex={helpIndex} />
        <button disabled={helpIndex >= helpData.length} css={navButtonStyle} data-testid="next">
          <Next onClick={onNextClick} />
        </button>
      </div>
      <HelpPanel {...help} />
    </div>
  );
};

export default HelpSection;
