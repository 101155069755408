//@flow
import type { Side, GameState, MoveSequence, MoveDirection, Move } from "./types";

const mod = (x: number, base: number) => {
  return x - Math.floor(x / base) * base;
};

export const OPPOSITE_INDICES = [7, 6, 5, 4, 3, 2, 1, 0];
const FORWARD: MoveDirection = 1;
const BACKWARD: MoveDirection = -1;
export const COMPUTER_SIDE = 0;

export const isKimbi = (index: number): boolean => [0, 1, 6, 7].includes(index);
export const isComputerSide = (side: Side | -1): boolean => side === COMPUTER_SIDE;
export const isInNamuaStage = (holes: GameState): boolean => sumHoles(holes[0]) + sumHoles(holes[1]) < 64;
export const playerWon = (gameState?: GameState): boolean => {
  if (!gameState) {
    return false;
  }
  if (isInNamuaStage(gameState)) {
    return isGameOverNamua(gameState);
  }
  return isGameOver(gameState[COMPUTER_SIDE]);
};

export const playerLost = (gameState?: GameState): boolean => {
  if (!gameState) {
    return false;
  }

  if (isInNamuaStage(gameState)) {
    return isGameOverNamua(gameState);
  }

  return isGameOver(gameState[opponent(COMPUTER_SIDE)]);
};
export const reset = (): number[] => Array(16).fill(2);
export const resetKiswahili = (): number[] => {
  let holes = Array(16).fill(0);
  holes[4] = 6;
  holes[5] = 2;
  holes[6] = 2;
  return holes;
};
export const getDirection = (start: number, end: number): MoveDirection => {
  if (start === 15 && end === 0) {
    return 1;
  } else if (start === 0 && end === 15) {
    return -1;
  }
  return start < end ? 1 : -1;
};

export function getFrontHoles(holes: number[]): number[] {
  return [...holes].slice(0, 8);
}

export function getRearHoles(holes: number[]): number[] {
  return [...holes].slice(8, 16);
}

export function countEmptyFrontHoles(holes: number[]): number {
  return getFrontHoles(holes).filter((hole) => hole === 0).length;
}
/**
 * gets the sum of the values in the holes for this player's side
 */
export function sumHoles(holes: number[]): number {
  return holes.reduce((a, b) => a + b);
}
/**
 * returns all indices that this player can play from
 */
export function getPlayableIndices(holes: number[], opponentHoles: number[]): number[] {
  let result = [];
 

  if (isInNamuaStage([holes, opponentHoles])) {
    holes.forEach((hole, index) => {
      if (hole > 0 && (index !== 4 || (index === 4 && hasNonEmptyOppositeHole(index, opponentHoles)))) {
        result.push(index);
      }
    });

    return result;
  }

  //game play stops when game is over
  if (isGameOver(holes) || isGameOver(opponentHoles)) {
    return result;
  }

  holes.forEach((val, index) => {
    if (val > 1) {
      result.push(index);
    }
  });
  return result;
}

/**
 * Gets the indices from which this player can start such that he capture
 * his opponent's hole
 * @return capturable starting indices or null
 */
export function getCapturableIndices(holes: number[], opponentHoles: number[]): Move[] {
  let playable = getPlayableIndices(holes, opponentHoles);

  const moves = playable.map((index) => ({
    index,
    directions: canCaptureFromIndex(index, holes, opponentHoles)
  }));

  return moves.filter((item) => item.directions.length > 0);
}
/**
 * For the Mtaji stage, Checks if the player can capture a piece if they chose to play from the current index. A player may not capture if the number of seeds in the starting hole is greater than 15.
 * For the namua stage, checks if the oppositeIndex has a nonempty hole. This is because we expect the user drops a seed at the designated index and captures the opponent's pieces.
 * returns the direction (FORWARD : 1, or BACKWARD: -1) from which the player can capture
 * from the current index
 */
export const canCaptureFromIndex = (index: number, holes: number[], opponentHoles: number[]): MoveDirection[] => {
  let newHoles = [...holes];
  let numberInHand = newHoles[index],
    result = [];
  if (isInNamuaStage([holes, opponentHoles])) {
    if (hasNonEmptyOppositeHole(index, opponentHoles) && newHoles[index] > 0) {
      result = [1, -1];
    }
    return result;
  }

  if (numberInHand < 2 || numberInHand > 15) {
    return [];
  }
  newHoles[index] = 0;
  //trying the FORWARD direction -- player can only capture if the index remains in the front row
  [FORWARD, BACKWARD].forEach((charge) => {
    const newIndex = normaliseIndex(index + charge * numberInHand);
    if (newIndex < 8) {
      if (hasNonEmptyOppositeHole(newIndex, opponentHoles) && newHoles[newIndex] > 0) {
        result.push(charge);
      }
    }
  });
  return result;
};

export const hasNonEmptyOppositeHole = (index: number, opponentHoles: number[]): boolean => {
  const oppositeIndex = OPPOSITE_INDICES[index];
  return opponentHoles[oppositeIndex] > 0;
};

export const normaliseIndex = (index: number): number => mod(index, 16);

const isZero = (d) => d === 0;

//game is over if all the holes contain 1 or zero or front row
//contains nothing
export const isGameOver = (holes: number[] = []): boolean => {
  return holes.every((hole) => hole <= 1) || getFrontHoles(holes).every(isZero);
};

export const isGameOverNamua = (state: GameState): boolean => {
  const hasZeroFrontHoles = getFrontHoles(state[0]).every(isZero) || getFrontHoles(state[1]).every(isZero);
  return hasZeroFrontHoles;
};
/**
 * checks if an index is in the front row
 */
const frontRow = (index) => index <= 7;

/**
 * Checks if a move is valid
 * @param index the index of the hole
 * @param direction the direction of the move
 * return true if move is valid or false otherwise
 */
export function isValidMove(index: number, direction: number, holes: number[], opponentHoles: number[]): boolean {
  let possibilities = getCapturableIndices(holes, opponentHoles);
  if (possibilities.length > 0) {
    return possibilities.some((item) => item.index === index && item.directions.includes(direction));
  } else {
    //there are no capturable indices
    let playableIndices = getPlayableIndices(holes, opponentHoles);
    //if there is any front row playable index you must play one of them
    if (playableIndices.some(frontRow)) {
      playableIndices = playableIndices.filter(frontRow);
    }
    return playableIndices.some((item) => item === index);
  }
}

/**
 * Retrieve the valid moves from the current state of the game
 * @returns
 */
export function getValidMoves(holes: number[], opponentHoles: number[]): Move[] {
  let result = [];
  const gameState = [holes, opponentHoles];
  if ((isInNamuaStage(gameState) && isGameOverNamua(gameState)) || (!isInNamuaStage(gameState) && isGameOver(holes))) {
    return result;
  }
  let possibilities = getCapturableIndices(holes, opponentHoles);
  if (possibilities.length > 0) {
    return possibilities;
  } else {
    //for non capturing moves, a player must play from the front row if they can
    //so if there is a playable front row index in the takasa the possibilities are
    //filtered to only contain the takasa
    let takasaPossibilities = getPlayableIndices(holes, opponentHoles);
    let frontRowTakasaPossibilities = takasaPossibilities.filter(frontRow);
    //since these are non capturing moves, player may play in either direction
    /*
     *although by default any direction is allowed unless a move causes the front row to be completely
     * empty however momentarily
     */
    let directions = [FORWARD, BACKWARD];
    if (frontRowTakasaPossibilities.length > 0) {
      const onlyOnePossibility = frontRowTakasaPossibilities.length === 1;
      return frontRowTakasaPossibilities.map((index) => ({
        index,
        directions:
          onlyOnePossibility && index === 0 ? [FORWARD] : onlyOnePossibility && index === 7 ? [BACKWARD] : directions
      }));
    } else {
      return takasaPossibilities.map((index) => ({ index, directions }));
    }
  }
}

export function gamestate(holes: number[], opponentHoles: number[]): GameState {
  return [[...holes], [...opponentHoles]];
}
/**
 * Recursively distributes the number of pebbles in the hand of the player
 * @param startIndex the start index ie where the first pebble will be dropped by the player
 * @param direction the direction of play (FORWARD:1, BACKWARD:-1)
 * @param numberInHand the number of pebbles to distribute
 * @return an array of playing sequence containing the index of play (i.e. where the
 * current pebble is beign dropped), the state of the game (i.e. the number of pebbles in
 * each hole) and the number left in the current player's hand
 */
export function distributeNumberInHand(
  startIndex: number,
  direction: MoveDirection,
  numberInHand: number,
  currentGameState: GameState,
  currentSide: Side
): MoveSequence[] {
  const holes = currentGameState[currentSide];
  const opponentHoles = currentGameState[opponent(currentSide)];

  const newHoles = [...holes];

  if (numberInHand < 1) {
    return [];
  } else {
    newHoles[startIndex] = newHoles[startIndex] + 1;
    numberInHand -= 1; //reduce the number in hand since we have incremented the hole at startIndex

    let nextIndex = direction > 0 ? startIndex + 1 : startIndex - 1;
    nextIndex = normaliseIndex(nextIndex);
    const newGameState = currentSide === 0 ? gamestate(newHoles, opponentHoles) : gamestate(opponentHoles, newHoles);
    return [
      {
        index: startIndex,
        state: newGameState,
        inHand: numberInHand,
        side: currentSide,
        action: 1
      }
    ].concat(distributeNumberInHand(nextIndex, direction, numberInHand, newGameState, currentSide));
  }
}

function pickAllSeedsAndContinue(
  index: number,
  direction: MoveDirection,
  canCapture: boolean,
  rdepth: number,
  currentGameState: GameState,
  currentSide: Side
): MoveSequence[] {
  const holes = currentGameState[currentSide];
  const opponentHoles = currentGameState[opponent(currentSide)];

  if (isGameOver(opponentHoles)) {
    return [];
  }
  if (rdepth > 100) {
    console.log("recursive call is taking forever... maybe mark this move for deletion");
    return [];
  }

  if (holes[index] > 1) {
    //pick up all the seeds from that hole and play
    let numberInHand = holes[index];
    const newHoles = [...holes];
    newHoles[index] = 0;
    const newGameState = currentSide === 0 ? gamestate(newHoles, opponentHoles) : gamestate(opponentHoles, newHoles);
    const action = [
      {
        index: index,
        state: newGameState,
        inHand: numberInHand,
        side: currentSide,
        action: -numberInHand
      }
    ];
    index = direction > 0 ? index + 1 : index - 1;
    return action.concat(
      play(normaliseIndex(index), direction, canCapture, numberInHand, rdepth, newGameState, currentSide)
    );
  } else {
    return []; //
  }
}

export const opponent = (side: Side): Side => (side === 0 ? 1 : 0);

export function play(
  index: number,
  direction: MoveDirection,
  canCapture: boolean,
  numberInHand: number,
  rdepth: number,
  currentGameState: GameState,
  currentSide: Side
): MoveSequence[] {
  let oppositeIndex: number, playSequence: MoveSequence[];
  rdepth = rdepth || 0;
  playSequence = distributeNumberInHand(index, direction, numberInHand, currentGameState, currentSide);

  if (playSequence.length === 0) {
    return playSequence;
  }
  const lastSequence = playSequence[playSequence.length - 1];
  const endIndex = lastSequence.index;
  const [side0, side1] = lastSequence.state;
  let [newHoles, newOpponentHoles] = currentSide === 0 ? [side0, side1] : [side1, side0];
  /**
   * if the player landed in the front row and can capture and the
   * last landing hole has more than 1 pebble
   */
  if (frontRow(endIndex) && canCapture && newHoles[endIndex] > 1) {
    oppositeIndex = OPPOSITE_INDICES[endIndex];
    /**
     * if opponent has any seeds in the opposite index then capture those
     * seeds
     */
    if (newOpponentHoles[oppositeIndex] > 0) {
      const opponentHolesAfterCapture = [...newOpponentHoles];
      numberInHand = opponentHolesAfterCapture[oppositeIndex];
      opponentHolesAfterCapture[oppositeIndex] = 0;

      const newGameState =
        currentSide === 0
          ? gamestate(newHoles, opponentHolesAfterCapture)
          : gamestate(opponentHolesAfterCapture, newHoles);
      playSequence.push({
        index: oppositeIndex,
        state: newGameState,
        inHand: numberInHand,
        side: opponent(currentSide),
        action: -numberInHand
      });
      //if opposite index is <=1 then need to go BACKWARD  and go BACKWARD if the opposite index >=6

      if (oppositeIndex <= 1) {
        return playSequence.concat(play(7, BACKWARD, canCapture, numberInHand, 0, newGameState, currentSide));
      } else if (oppositeIndex >= 6) {
        return playSequence.concat(play(0, FORWARD, canCapture, numberInHand, 0, newGameState, currentSide));
      } else {
        //normal continous play in the current direction
        index = direction === FORWARD ? 0 : 7;
        return playSequence.concat(play(index, direction, canCapture, numberInHand, 0, newGameState, currentSide));
      }
    } else {
      return playSequence.concat(
        pickAllSeedsAndContinue(endIndex, direction, canCapture, rdepth + 1, lastSequence.state, currentSide)
      );
    }
  } else if (newHoles[endIndex] === 1) {
    //end of turn
    return playSequence;
  } else {
    //landed in back row
    return playSequence.concat(
      pickAllSeedsAndContinue(endIndex, direction, canCapture, rdepth + 1, lastSequence.state, currentSide)
    );
  }
}

/**
 *
 * @param index
 * @param direction
 * @returns a play sequence containing the following properties
 * index: the index of the hole affected
 * state:the current state of the 32 holes in the game
 * inHand:the number of pebbles currently in the hand of the player
 * side: the side of the hole affected
 * action:the type of action that affects the hole. this is usually a positive or negative number
 * denoting a player adding or removing pebbles from a hole
 */
export const userPlay = (
  index: number,
  direction: MoveDirection,
  side: Side,
  currentGameState: GameState
): MoveSequence[] => {
  const holes = currentGameState[side];
  const opponentHoles = currentGameState[opponent(side)];

  let validDirections,
    canCapture,
    numberInHand,
    playsequence: MoveSequence[] = [];
  if (isValidMove(index, direction, holes, opponentHoles)) {
    //if we are in namua stage, we place a seed in hand and start from index
    if (isInNamuaStage(currentGameState)) {
      const canCapture = hasNonEmptyOppositeHole(index, opponentHoles);
      numberInHand = 1;
      return play(index, direction, canCapture, numberInHand, 0, currentGameState, side);
    }

    validDirections = canCaptureFromIndex(index, holes, opponentHoles);
    const newHoles = [...holes];
    numberInHand = newHoles[index];

    newHoles[index] = 0;
    const newGameState: GameState = [[], []];
    newGameState[side] = newHoles;
    newGameState[opponent(side)] = opponentHoles;

    const sequence: MoveSequence = {
      index: index,
      state: newGameState,
      inHand: numberInHand,
      side,
      action: -numberInHand
    };
    playsequence = [sequence];
    canCapture = validDirections && validDirections.includes(direction);
    index = direction === FORWARD ? normaliseIndex(index + 1) : normaliseIndex(index - 1);

    return playsequence.concat(play(index, direction, canCapture, numberInHand, 0, newGameState, side));
  } else {
    console.log("invalid attempt to play from index " + index + " direction " + direction);
  }
  return playsequence;
};
