//@flow
import type { Element } from "React";
import ReactMarkdown from "react-markdown";

const styles = ({ gridUnit }) => ({
  marginTop: gridUnit * 5,
  width: "100%",
  height: gridUnit * 60,
  overflowY: "auto",
  boxSizing: "border-box",
  lineHeight: `${gridUnit * 8}px`,
  p: {
    fontSize: gridUnit * 4,
    lineHeight: `${gridUnit * 6}px`,
    margin: 0
  }
});
export const HelpInstruction = (props: { text: string }): Element<"div"> => (
  <div css={styles}>
    <ReactMarkdown children={props.text} />
  </div>
);
