// @flow
import type { Element } from "React";
import type { PlayAction, ResetGameAction } from "../../reducer.js";
import { isComputerSide } from "../../core/baoside.js";
import type { MoveSequence, Side } from "../../core/types.js";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { logPageView } from "../../hooks/use-analytics";

type Props = {
  path: string,
  moveHistory: Array<{ action: PlayAction | ResetGameAction, sequence: Array<MoveSequence> }>
};

const tableStyle = (theme) => ({
  width: "100%",
  textAlign: "center",
  borderCollapse: "collapse"
});
const trStyle = (theme) => ({
  tableLayout: "fixed",
  padding: theme.gridUnit,
  display: "table",
  width: "100%"
});

const theadStyle = (theme) => ({
  borderBottom: "2px solid black",
  ...trStyle(theme)
});
const tbodyStyle = (theme) => ({
  height: theme.boardHeight,
  display: "block",
  overflowY: "auto"
});

const History = (props: Props): Element<"table"> => {
  const history = props.moveHistory;
  const playActions: Array<{
    action: PlayAction,
    sequence: Array<MoveSequence>
  }> = (history.filter((d) => d.action.type === "USER_PLAY"): any);
  useEffect(() => {
    logPageView(
      "Bao | Game history",
      window.location.href
    )
  })
  return (
    <table css={tableStyle}>
      <Helmet>
        <title>Bawo | Game history</title>
        <meta name="description" content="Game history" />
      </Helmet>
      <thead css={theadStyle}>
        <tr>
          <th>User</th> <th>Move Index</th> <th>Move Direction</th>
        </tr>
      </thead>
      <tbody css={tbodyStyle}>
        {playActions.map((d, index) => (
          <MoveRow key={`row_${index}`} user={d.action.side} index={d.action.index} direction={d.action.direction} />
        ))}
      </tbody>
    </table>
  );
};

type MoveRowProps = {
  user: Side,
  index: number,
  direction: number
};
const MoveRow = (props: MoveRowProps) => {
  return (
    <tr css={trStyle}>
      <td>{isComputerSide(props.user) ? "Bot" : "You"}</td>
      <td>{props.index}</td>
      <td>{props.direction}</td>
    </tr>
  );
};

export default History;
