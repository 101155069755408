//@flow
import type { Element } from "React";
import { useState, useContext } from "react";
import Hole from "../Hole";
import StoreHole from "../Hole/StoreHole.jsx";
import { ActiveMarker } from "./ActiveMarker";
import classnames from "classnames/bind";
import OrientationContext from "../../components/providers/OrientationProvider.jsx";
import BoardPropertiesContext from "../../components/providers/BoardPropertiesProvider.jsx";
import { SowingDirectionLeft, SowingDirectionRight } from "../SowingDirection";

import type { Side as GameSide, Move } from "../../core/types";
import { OPPOSITE_INDICES, opponent } from "../../core/baoside";

import styles from "./Side.module.css";
export type Props = {
  frontHoles: number[],
  rearHoles: number[],
  adjacentHoles: number[],
  validMoves: Move[],
  activeIndex: number,
  side: GameSide,
  isActive: boolean,
  idle?: boolean,
  store?: number,
  dispatch: *
};

const cx = classnames.bind(styles);
function getAdjacentSeeds(index, adjacentHoles) {
  return adjacentHoles[OPPOSITE_INDICES[index]];
}
const Side = ({
  store,
  frontHoles,
  rearHoles,
  adjacentHoles,
  side,
  activeIndex,
  validMoves = [],
  isActive,
  idle,
  dispatch
}: Props): Element<"div"> => {
  const [needsCaptureSowingDirection, setNeedsCaptureSowingDirection] = useState(null);
  const orientation = useContext(OrientationContext);
  const { boardProperties } = useContext(BoardPropertiesContext);
  const highlightedHoles = boardProperties.highlightedHoles;
  return (
    <div className={cx({ sideWrapper: true })}>
      <div
        className={cx({
          boardSide: side === 1,
          boardSide0: side === 0
        })}
      >
        <div className={cx("front")}>
          {frontHoles.map((seeds, index) => {
            const validMove = validMoves.find((move) => move.index === index);
            let sowingDirection = null;
            if (needsCaptureSowingDirection) {
              if (index === 0) {
                sowingDirection = (
                  <SowingDirectionRight
                    onClick={(event: SyntheticEvent<HTMLDivElement>) => {
                      event.stopPropagation();
                      needsCaptureSowingDirection.sowRight();
                      setNeedsCaptureSowingDirection(null);
                    }}
                  />
                );
              } else if (index === 7) {
                sowingDirection = (
                  <SowingDirectionLeft
                    onClick={(event: SyntheticEvent<HTMLDivElement>) => {
                      event.stopPropagation();
                      needsCaptureSowingDirection.sowLeft();
                      setNeedsCaptureSowingDirection(null);
                    }}
                  />
                );
              }
            }
            return (
              <Hole
                dispatch={dispatch}
                isValidMove={validMove !== undefined}
                showHint={idle === true && validMove !== undefined}
                playableDirections={validMove ? validMove.directions : []}
                isActive={activeIndex < 8 && activeIndex === index}
                index={index}
                key={`side_${side}_${index}_front`}
                seeds={seeds}
                adjacentSeeds={getAdjacentSeeds(index, adjacentHoles)}
                side={side}
                currentActiveSide={isActive ? side : opponent(side)}
                isHighlighted={highlightedHoles[side].includes(index)}
                setNeedsCaptureSowingDirection={setNeedsCaptureSowingDirection}
              >
                {sowingDirection}
              </Hole>
            );
          })}
        </div>
        <div className={cx("rear", orientation)}>
          {rearHoles.map((seeds, index) => {
            const sideIndex = index + 8;
            const validMove = validMoves.find((move) => move.index === sideIndex);

            return (
              <Hole
                dispatch={dispatch}
                isValidMove={validMove !== undefined}
                showHint={idle === true && validMove !== undefined}
                playableDirections={validMove ? validMove.directions : []}
                isActive={activeIndex === sideIndex}
                index={sideIndex}
                key={`side_${side}_${sideIndex}_rear`}
                seeds={seeds}
                side={side}
                currentActiveSide={isActive ? side : opponent(side)}
                isHighlighted={highlightedHoles[side].includes(sideIndex)}
                setNeedsCaptureSowingDirection={setNeedsCaptureSowingDirection}
              />
            );
          })}
        </div>
        <div css={{ textAlign: "center" }}>
          {store !== undefined && store >= 0 && <StoreHole seeds={store} side={side} dispatch={dispatch} />}
        </div>
      </div>
      {isActive && <ActiveMarker />}
    </div>
  );
};

export default Side;
