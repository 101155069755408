//@flow
import * as React from "react";

type Props = {
  path?: string,
  children: React.Node
};

const modalStyle = {
  position: "absolute",
  background: "white",
  zIndex: 3,
  top: 0
};
const Modal = (props: Props): React.Element<"div"> => {
  return <div css={modalStyle}>{props.children}</div>;
};

export default Modal;
