// @flow
import type { Element } from "React";

type RadioButtonProps = {
  name: string,
  id: string,
  checked: boolean,
  label: string,
  value: string | number,
  onChange: (event: SyntheticEvent<HTMLInputElement>) => void
};
const labelWrapperStyle = (theme) => ({
  height: theme.gridUnit * 5,
  width: 140,
  lineHeight: `${theme.gridUnit * 5}px`,
  verticalAlign: "middle",
  display: "flex"
});
const radioIconStyle = (theme) => ({
  boxSizing: "border-box",
  display: "inline-block",
  cursor: "pointer",
  border: `${theme.gridUnit}px solid ${theme.colors.grey8}`,
  background: "white",
  borderRadius: "100%",
  marginRight: 10,
  height: theme.gridUnit * 5,
  width: theme.gridUnit * 5,
  "&::before": {
    content: "''",
    margin: "auto"
  }
});
const radioInputStyles = (theme) => ({
  position: "absolute",
  visibility: "hidden",
  "&:checked ~ i": {
    border: `${theme.gridUnit}px solid ${theme.colors.grey1}`,
    boxShadow: `0px 0px ${theme.gridUnit}px black`,
    background: theme.colors.grey4
  }
});
const RadioButton = (props: RadioButtonProps): Element<"label"> => {
  const { label, checked, id, value, onChange } = props;
  return (
    <label htmlFor={label} key={label} css={labelWrapperStyle}>
      <input
        type="radio"
        css={radioInputStyles}
        checked={checked}
        id={id}
        value={value}
        name={props.name}
        onChange={onChange}
      />
      <i css={radioIconStyle}></i>
      <span>{label}</span>
    </label>
  );
};

export default RadioButton;
