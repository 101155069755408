//@flow
import type { Node } from "React";
import { useState, useContext, useReducer, useEffect } from "react";
import queryString from "query-string";
import { Router, useLocation } from "@reach/router";
import Board from "./Board";
import { kiswahiliStartState, gameStateReducer, initialState } from "src/reducer";
import Modal from "src/components/Modal";
import SettingsForm from "src/components/SettingsForm";
import History from "src/components/History";
import HelpSection from "src/components/HelpSection";
import Toolbar from "../Toolbar";
import { sumHoles, isInNamuaStage, opponent, COMPUTER_SIDE, playerWon, playerLost } from "src/core/baoside";
import { alphaBeta, KISWAHILI } from "src/core/baogame";
import SelectedHoleContext from "src/components/providers/SelectedHoleProvider.jsx";
import GameSettingsContext, { defaultGameSettings } from "src/components/providers/GameSettingsProvider.jsx";
import GameOverModal from "../GameOverModal";

import OrientationContext, { PORTRAIT } from "src/components/providers/OrientationProvider.jsx";
import { logGameLost, logGameStarted, logGameWon } from "../../hooks/use-analytics";

type Props = {
  path: string
};

const searchDepth = {
  beginner: 1,
  intermediate: 2,
  expert: 5
};
const PLAY_SWAP_INTERVAL = 1500;
const isStartingState = (gameState) => gameState[0].every((d) => d === 2) && gameState[1].every((d) => d === 2);
const BoardContainer = (props: Props): Node => {
  const location = useLocation();
  const query = queryString.parse(location.search);
  const rawHash = decodeURIComponent(location.hash.substr(1));
  const hash = rawHash !== "" ? JSON.parse(rawHash) : {};

  const startingSide = query.startingSide === "0" ? 0 : 1;
  const [settings, setGameSettings] = useState(defaultGameSettings.settings);
  const [gameType, setGameType] = useState(query.gameType || "0");
  const [gameStage, setGameStage] = useState(gameType === KISWAHILI ? "namua" : "mtaji");

  const orientation = useContext(OrientationContext);

  const startingGameState = gameType === KISWAHILI ? kiswahiliStartState : initialState.currentGameState;
  const [sideA, sideB] = startingGameState;
  const store = (64 - sumHoles(sideA) - sumHoles(sideB)) / 2;
  const [state, dispatch] = useReducer(gameStateReducer, {
    ...initialState,
    currentGameState: hash.gameState || startingGameState,
    store,
    currentPlayer: hash.nextPlayer || startingSide,
    settings: {
      ...settings,
      startingSide: startingSide
    }
  });
  const currentPlayer = state.currentPlayer;
  const gameState = state.currentGameState;
  const currentSequence = state.currentSequence[state.currentSequenceIndex];
  const currentSequenceHoleIndex = currentSequence ? currentSequence.index : -1;

  const activeIndex = currentSequenceHoleIndex;
  const endSequence =
    state.currentSequence.length > 0 && state.currentSequenceIndex === state.currentSequence.length - 1;

  const won = playerWon(gameState);
  const lost = playerLost(gameState);
  const gameOver = endSequence && (won || lost);
  const playbackSpeed = settings.playbackSpeed;
  const currentSequenceSide = currentSequence ? currentSequence.side : currentPlayer;

  const boardContainerStyle = (theme) => {
    let boardWidth = (theme.holeWidth + 16) * 8 + 10;
    let boardHeight = (theme.holeHeight + 16) * 4 + 10;

    const xOffset = gameType === KISWAHILI ? 35 : 0;
    return orientation === PORTRAIT
      ? {
          transformOrigin: "top left",
          transform: `translate(${boardHeight + xOffset}px) rotate(90deg)`,
          height: boardHeight,
          width: boardWidth
        }
      : {};
  };
  // useEffect(() => {
  //   if (endSequence) {
  //     const hash = {
  //       gameState,
  //       nextPlayer: opponent(currentPlayer)
  //     };
  //     const encodedGameState = encodeURIComponent(JSON.stringify(hash));
  //     document.location.hash = `#${encodedGameState}`;
  //   }
  // }, [endSequence, gameState, currentPlayer]);

  useEffect(() => {
    setGameStage(gameState && isInNamuaStage(gameState) ? "namua" : "mtaji");
  }, [gameState]);

  useEffect(() => {
    if (activeIndex !== -1 && !endSequence) {
      const id = setInterval(() => {
        dispatch({ type: "SEQUENCE_STEP_FORWARD" });
      }, playbackSpeed);
      return () => clearInterval(id);
    } else if (endSequence && !gameOver) {
      const id = setTimeout(() => {
        dispatch({
          type: "CHANGE_PLAYER",
          side: opponent(currentPlayer)
        });
      }, PLAY_SWAP_INTERVAL);
      return () => clearTimeout(id);
    }
  }, [activeIndex, currentPlayer, dispatch, endSequence, gameOver, playbackSpeed]);

  useEffect(() => {
    if (!gameOver) {
      return;
    }
    const numberOfTurns = state.moveHistory.length;
    const gameAttributes = {
      numberOfTurns,
      startingSide,
      gameType,
      expertLevel: settings.expertLevel
    };

    if (won) {
      logGameWon(gameAttributes);
    } else {
      logGameLost(gameAttributes);
    }
  }, [gameOver, settings.expertLevel, startingSide, state.moveHistory, gameType, won]);

  useEffect(() => {
    if (
      (gameOver && state.currentSequenceIndex === state.currentSequence.length - 1) ||
      state.thinking ||
      currentPlayer !== COMPUTER_SIDE ||
      state.currentSequenceIndex !== -1
    ) {
      return;
    }
    dispatch({ type: "THINKING" });
    const adjustedDepth = isStartingState(gameState) ? 1 : searchDepth[settings.expertLevel] || 1;

    setTimeout(() => {
      const [bestMove] = alphaBeta(gameState, currentPlayer, adjustedDepth);
      if (bestMove) {
        const action = {
          type: "USER_PLAY",
          index: bestMove.index,
          direction: bestMove.direction,
          side: currentPlayer
        };
        setTimeout(() => {
          dispatch(action);
        }, PLAY_SWAP_INTERVAL);
      } else {
        console.log("problem getting state");
        console.log(gameState, currentPlayer, adjustedDepth);
      }
    }, 500);
  });

  useEffect(() => {
    logGameStarted({ gameType, startingSide, expertLevel: settings.expertLevel });
  }, [startingSide, gameType, settings.expertLevel]);

  return (
    <SelectedHoleContext.Provider value={state.gamePlay}>
      <GameSettingsContext.Provider
        value={{
          settings,
          gameStage,
          gameType,
          setGameStage,
          setGameType,
          setGameSettings
        }}
      >
        <Toolbar hash="" />
        <div css={boardContainerStyle} data-testid="board-container">
          <Board
            dispatch={dispatch}
            gameType={gameType}
            store={state.store}
            startingSide={startingSide}
            gameState={gameState}
            activeSide={currentSequenceSide}
            currentPlayer={currentPlayer}
            activeIndex={activeIndex}
            idle={endSequence || state.currentSequenceIndex === -1}
          >
            {gameOver ? <GameOverModal won={won} /> : null}
            <Router>
              <Modal path="settings">
                <SettingsForm path="/" />
              </Modal>
              <Modal path="help">
                <HelpSection path="/" />
              </Modal>
              <Modal path="history">
                <History path="/" moveHistory={state.moveHistory} />
              </Modal>
              <GameOverModal path="restart" />
            </Router>
          </Board>
        </div>
      </GameSettingsContext.Provider>
    </SelectedHoleContext.Provider>
  );
};

export default BoardContainer;
