//@flow
import type { Node } from "React";
import PhysicsHole from "src/components/Hole/PhysicsHole";

const buttonStyle = (theme) => ({
  ...theme.button,
  width: 100,
  textAlign: "center",
  boxShadow: "0 1px 1px 1px",
  marginTop: 20
});
type Props = {
  path: string
};
const Home = (props: Props): Node => {
  return (
      <div
        css={{
          position: "fixed",
          left: 0,
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column"
        }}
      >
        <PhysicsHole holeWidth={300} holeHeight={300} seeds={32} />
        <a css={buttonStyle} href="/game">
          Play now
        </a>
        <a css={buttonStyle} href="/game/help">
          How to play
        </a>
        <a css={buttonStyle} href="/contact">
          Feedback
        </a>
      </div>
  );
};

export default Home;
