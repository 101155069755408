//@flow
/**
 * @author hogfather
 * @date Mar 9, 2012
 * @project JSLib
 */
import type { Move, GameState, Side, MoveDirection } from "./types";
import { isGameOver, sumHoles, userPlay, getValidMoves, opponent } from "./baoside";

// game types
export const KUJIFUNZA = "1";
export const KISWAHILI = "2";

// game stages
export const NAMUA = "namua";
export const MTAJI = "mtaji";

type SingleMove = {
  index: number,
  direction: MoveDirection
};

export function expandMoves(moves: Move[]): SingleMove[] {
  let res = [];
  moves.forEach(({ directions, index }) => {
    directions.forEach((direction) => {
      res.push({ index, direction });
    });
  });
  return res;
}

export const HIGH = 128;
export const LOW = -1;
type ComputeArgs = {
  state: GameState,
  player: Side,
  move: SingleMove,
  depth: number,
  alpha: number,
  beta: number
};
/**
 * helper function to recursively compute the strength of a move using the alpha beta pruning
 * algorithm
 */
export function compute(params: ComputeArgs, maxPlayer: Side): number {
  let { state, player, move, depth, alpha, beta } = params;
  //make the move if the moveSequence is empty then it is bad for the maxPlayer and good for the min player
  const moveSequence = userPlay(move.index, move.direction, player, state);
  if (moveSequence.length === 0) {
    return player === maxPlayer ? LOW : HIGH;
  }

  const newState = moveSequence[moveSequence.length - 1].state;
  // return a high score if minPlayer loses and a low score if maxPlayer loses
  if (isGameOver(newState[opponent(player)])) {
    return player === maxPlayer ? HIGH : LOW;
  } else if (isGameOver(newState[player])) {
    return player === maxPlayer ? LOW : HIGH;
  }

  // if we get to the end of the computation and the game is not yet over,
  // return the sum of the holes of the end state as the score
  if (depth === 0) {
    return sumHoles(newState[player]);
  }

  const opponentValidMoves = getValidMoves(newState[opponent(player)], newState[player]);
  const opponentMoves = expandMoves(opponentValidMoves);
  if (player === maxPlayer) {
    //max branch
    //give a very high score if this move means max player has won
    if (opponentMoves.length === 0) {
      return HIGH;
    }
    //this is the opponent and the opponent will play the move that minimises the player's score
    for (let i = 0; i < opponentMoves.length; i++) {
      const betaScore = compute(
        {
          state: [...newState],
          player: opponent(player),
          move: opponentMoves[i],
          depth: depth - 1,
          alpha: alpha,
          beta: beta
        },
        maxPlayer
      );
      if (betaScore === LOW) {
        return betaScore;
      }
      beta = Math.min(beta, betaScore);

      if (alpha >= beta) {
        break;
      }
    } //end for

    return beta;
  } else {
    //min branch
    //give a very low score if this move means max player has lost
    if (opponentMoves.length === 0) {
      return LOW;
    }
    //this is the opponent and the opponent will play the move that minimises the player's score
    for (let i = 0; i < opponentMoves.length; i++) {
      const alphaScore = compute(
        {
          state: [...newState],
          player: opponent(player),
          move: opponentMoves[i],
          depth: depth - 1,
          alpha,
          beta
        },
        maxPlayer
      );

      if (alphaScore === HIGH) {
        return alphaScore;
      }
      alpha = Math.max(alpha, alphaScore);

      if (alpha >= beta) {
        break;
      }
    } //end for

    return alpha;
  }
}

/**
 * returns a list of moves for the current side from the current state
 * @param state the current state to explore from
 * @param s the side to explore for
 * returns a list of moves with scores signifying the strength of the move
 */
export function alphaBeta(state: GameState, maxPlayer: Side, searchDepth: number = 2): SingleMove[] {
  const alpha = Number.MIN_SAFE_INTEGER,
    beta = Number.MAX_SAFE_INTEGER;

  const playerMoves = getValidMoves(state[maxPlayer], state[opponent(maxPlayer)]);
  const possibleMoves = expandMoves(playerMoves);
  const moves = possibleMoves.map((move) => ({
    move: move,
    score: compute(
      {
        state,
        player: maxPlayer,
        move: move,
        depth: searchDepth,
        alpha: alpha,
        beta: beta
      },
      maxPlayer
    )
  }));
  return moves.sort((first, second) => second.score - first.score).map((d) => d.move);
}

type GameTree = {
  move?: SingleMove,
  state: GameState,
  player: Side,
  children: GameTree[]
};
/**
 * explores the game from from the given state to the required depth
 * @state the state to start the exploration from
 * @player the side to explore for
 * @depth the level to stop the exploration
 * returns a tree structure of state and children {state:Array, player: int, children:Array}
 */
export function exploreGameTree(state: GameState, player: Side, depth: number): GameTree {
  if (depth === 0) {
    return {
      state: state,
      player: player,
      children: []
    };
  }

  const validMoves = getValidMoves(state[player], state[opponent(player)]);
  const moves = expandMoves(validMoves);
  let children = [];
  moves.forEach((move) => {
    const moveSequence = userPlay(move.index, move.direction, player, state); //play the move
    const tempState = moveSequence[moveSequence.length - 1].state;
    children = children.concat(exploreGameTree(tempState, opponent(player), depth - 1));
  });

  return {
    state,
    player,
    children
  };
}

const exploreState = (state: GameState, player: Side) => {
  const validMoves = getValidMoves(state[player], state[opponent(player)]);
  const expandedMoves = expandMoves(validMoves);

  return {
    state,
    player,
    children: expandedMoves.map((move) => {
      const sequence = userPlay(move.index, move.direction, player, state);
      const newState = sequence[sequence.length - 1].state;
      return { move, state: newState, player: opponent(player), children: [] };
    })
  };
};

export const exploreGameTreeBFS = (
  startState: GameState,
  player: Side,
  depth: number,
  move?: MoveDirection
): GameTree => {
  let gameTree: GameTree = {
    state: startState,
    player,
    children: []
  };
  let currentDepth = depth;

  let unexploredSubTrees = [gameTree];
  let unexploredIndex = 0;

  let unexploredSubTreesForNextLevel = [];

  while (currentDepth > 0 && unexploredSubTrees.length > 0) {
    const node = unexploredSubTrees[unexploredIndex];
    const { state, player: maxPlayer } = node;

    if (!isGameOver(state[maxPlayer]) && !isGameOver(state[opponent(maxPlayer)])) {
      const exploredState = exploreState(state, maxPlayer);
      // if there is a winning move here ignore all other children
      // expect that maxPlayer sees and plays any winning moves if they exist
      // and expect that opponent sees and plays any winning moves if they exist
      if (player === maxPlayer) {
        node.children = exploredState.children.filter((n) => isGameOver(n.state[opponent(player)]));
        if (node.children.length === 0) {
          node.children = exploredState.children;
        }
      } else {
        node.children = exploredState.children.filter((n) => isGameOver(n.state[player]));
        if (node.children.length === 0) {
          node.children = exploredState.children;
        }
      }
      unexploredSubTreesForNextLevel = unexploredSubTreesForNextLevel.concat(node.children);
    }

    unexploredIndex++;
    if (unexploredIndex >= unexploredSubTrees.length) {
      currentDepth--;
      unexploredSubTrees = unexploredSubTreesForNextLevel;
      unexploredSubTreesForNextLevel = [];
      unexploredIndex = 0;
      console.log("exploring", unexploredSubTrees);
    }
  }

  return gameTree;
};
