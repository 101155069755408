//@flow

import * as React from "react";
import type { Settings, GameType } from "../../core/types";
import { KUJIFUNZA } from "../../core/baogame.js";

type GameStage = "mtaji" | "namua";
type ContextType = {
  gameType: GameType,
  gameStage: GameStage,
  settings: Settings,
  setGameType?: (GameType) => void,
  setGameStage?: (GameStage) => void,
  setGameSettings?: (Settings) => void
};
export const defaultGameSettings = {
  gameType: KUJIFUNZA,
  gameStage: "mtaji",
  settings: {
    playbackSpeed: 400,
    expertLevel: "expert",
    startingSide: 0
  }
};
const GameSettingsContext: React$Context<ContextType> = React.createContext<ContextType>(defaultGameSettings);
export default GameSettingsContext;
