//@flow
import {
  opponent,
  isValidMove,
  getDirection,
  userPlay,
  gamestate,
  reset,
  resetKiswahili,
  sumHoles
} from "./core/baoside";
import type { GameState, GameType, MoveSequence, Side, MoveDirection, Settings } from "./core/types";
export type UserClickStoreAction = {
  type: "USER_CLICK_STORE",
  side: Side
};

type UserClickHoleAction = {
  type: "USER_CLICK_HOLE",
  side: Side,
  index: number
};

export type ResetGameAction = { type: "RESET_GAME", startingSide: Side };
type GameOverAction = { type: "GAME_OVER" };
type ThinkingAction = { type: "THINKING" };
export type PlayAction = {
  type: "USER_PLAY",
  side: Side,
  index: number,
  direction: MoveDirection
};

export type ChangePlayerAction = {
  type: "CHANGE_PLAYER",
  side: Side
};

export type SequenceStepForwardAction = {
  type: "SEQUENCE_STEP_FORWARD"
};

export type UpdateSettingsAction = {
  type: "UPDATE_SETTINGS",
  settings: Settings
};

export type Action =
  | UpdateSettingsAction
  | GameOverAction
  | PlayAction
  | ChangePlayerAction
  | ResetGameAction
  | ThinkingAction
  | UserClickHoleAction
  | UserClickStoreAction
  | SequenceStepForwardAction;

type State = {
  moveHistory: Array<{ action: PlayAction | ResetGameAction, sequence: MoveSequence[] }>,
  currentGameState: GameState,
  store: number,
  currentSequence: MoveSequence[],
  gamePlay?: {
    index: number,
    side: Side,
    storeClicked?: boolean
  },
  currentPlayer: Side,
  currentSequenceIndex: number,
  thinking: boolean,
  settings: Settings,
  gameType?: GameType,
  gameInProgress: boolean
};

export const kiswahiliStartState: GameState = gamestate(resetKiswahili(), resetKiswahili());
export const initialState = {
  moveHistory: ([]: Array<any>),
  currentGameState: (gamestate(reset(), reset()): GameState),
  store: 0,
  currentSequence: ([]: Array<any>),
  currentSequenceIndex: -1,
  thinking: false,
  currentPlayer: 0,
  gameInProgress: false,
  settings: {
    playbackSpeed: 250,
    expertLevel: "expert",
    startingSide: 0
  }
};

export const gameStateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "USER_CLICK_STORE":
      if (state.gamePlay && state.gamePlay.storeClicked) {
        return { ...state, gamePlay: undefined };
      }
      return { ...state, gamePlay: { side: action.side, storeClicked: true, index: -1 } };
    case "USER_CLICK_HOLE":
      const gamePlay = state.gamePlay;
      if (!gamePlay) {
        return {
          ...state,
          gamePlay: { index: action.index, side: action.side }
        };
      }

      const currentPlayer = action.side;
      const currentGameState = state.currentGameState;
      const startingIndex = gamePlay.index;
      const direction = getDirection(startingIndex, action.index);
      if (
        (startingIndex === action.index && gamePlay.side === action.side) ||
        !isValidMove(
          startingIndex,
          direction,
          currentGameState[currentPlayer],
          currentGameState[opponent(currentPlayer)]
        )
      ) {
        return {
          ...state,
          gamePlay: undefined
        };
      }
      const playAction = {
        type: "USER_PLAY",
        index: startingIndex,
        side: gamePlay.side,
        direction
      };
      return gameStateReducer(state, playAction);
    case "UPDATE_SETTINGS":
      return {
        ...state,
        settings: action.settings
      };
    case "SEQUENCE_STEP_FORWARD":
      const maxIndex = state.currentSequence.length > 0 ? state.currentSequence.length - 1 : 0;
      const currentSequenceIndex = Math.min(maxIndex, state.currentSequenceIndex + 1);
      return {
        ...state,
        currentSequenceIndex,
        currentGameState: state.currentSequence[currentSequenceIndex].state
      };
    case "USER_PLAY": {
      const { index, direction, side } = action;
      const sequence = userPlay(index, direction, side, state.currentGameState);
      const maxIndex = sequence.length > 0 ? sequence.length - 1 : 0;
      const [sideA, sideB] = sequence[maxIndex].state;
      const store = (64 - sumHoles(sideA) - sumHoles(sideB)) / 2;
      return {
        ...state,
        store,
        gamePlay: undefined,
        moveHistory: [
          ...state.moveHistory,
          {
            action,
            sequence
          }
        ],
        currentGameState: sequence.length > 0 ? sequence[0].state : state.currentGameState,
        currentSequence: sequence,
        currentSequenceIndex: 0,
        thinking: false,
        currentPlayer: action.side
      };
    }
    case "CHANGE_PLAYER":
      return {
        ...state,
        currentSequenceIndex: -1,
        currentSequence: [],
        currentPlayer: action.side
      };
    case "THINKING":
      return { ...state, thinking: true };
    case "RESET_GAME":
      return {
        ...initialState,
        settings: { ...state.settings },
        currentPlayer: action.startingSide,
        gameInProgress: true,
        moveHistory: [{ action, sequence: [] }]
      };
    case "GAME_OVER":
      return { ...state, gameInProgress: false, currentPlayer: 0 };
    default:
      return state;
  }
};
