//@flow
import type { Element } from "React";
import { useState } from "react";
import type { GameType } from "src/core/types";
import { KISWAHILI, KUJIFUNZA } from "src/core/baogame.js";
import RadioButton from "../RadioButton";
import RadioButtonGroup from "../RadioButtonGroup";

const enableKiswahili = true;
const gameOverOverlayStyle = (theme) => ({
  position: "absolute",
  top: 0,
  zIndex: 1,
  background: "#ffffff11",
  display: "flex",
  width: "100%",
  height: "100%",
  justifyContent: "center",
  alignItems: "center"
});
const gameOverDialogStyle = (theme) => ({
  background: "white",
  padding: theme.gridUnit * 5,
  boxShadow: `0 0 ${theme.gridUnit * 3}px 1px`,
  borderRadius: theme.gridUnit,
  textAlign: "center",
  div: { padding: theme.gridUnit * 2 }
});

type Props = {
  won?: boolean,
  path?: string,
  gameType?: GameType
};
const gameTypes = [
  { label: "Bao la Kujifunza", value: KUJIFUNZA },
  { label: "Bao la Kiswahili", value: KISWAHILI }
];
const GameOverModal = (props: Props): Element<"div"> => {
  const [computerStarts, setComputerStarts] = useState(false);

  const [gameType, setGameType] = useState(props.gameType || "");

  const onGameTypeChange = (event) => {
    setGameType(event.currentTarget.value);
  };

  return (
    <div css={gameOverOverlayStyle}>
      <div css={gameOverDialogStyle}>
        {props.won !== undefined ? <div>You {props.won ? "won :)!" : "lost :( ..."}</div> : null}
        <div>
          <label for="computerStarts">
            <span css={{ marginRight: 20 }}>Computer starts</span>
            <input
              value={computerStarts}
              onChange={(event) => {
                setComputerStarts(event.target.checked);
              }}
              type="checkbox"
              id="computerStarts"
            />
          </label>
        </div>
        {enableKiswahili && (
          <div>
            <RadioButtonGroup>
              {gameTypes.map(({ label, value }) => (
                <RadioButton
                  label={label}
                  key={label}
                  id={label}
                  onChange={onGameTypeChange}
                  value={value}
                  checked={gameType === value}
                  name="gameType"
                />
              ))}
            </RadioButtonGroup>
          </div>
        )}
        <div>
          <a css={(theme) => theme.button} href={`/game?startingSide=${computerStarts ? 0 : 1}&gameType=${gameType}`}>
            Start a new game
          </a>
        </div>
      </div>
    </div>
  );
};

export default GameOverModal;
