//@flow
import * as React from "react";

export const LANDSCAPE = "landscape";
export const PORTRAIT = "portrait";

export type Orientation = "portrait" | "landscape";

const OrientationContext: React$Context<Orientation> = React.createContext<Orientation>(LANDSCAPE);
export default OrientationContext;
