// @flow

const gridUnit = 4;
const boardWidth = 600;
const boardHeight = 284;
const iconSize = 35;
const holeHeight = 66;
const holeWidth = 82;
const toolbarHeight = 93;

const activeGreen = "#18af24";
const sowingDirection = "#515354c0";
const sowingDirectionDarker = "#515354f2";
const colors = {
  boardSide1: "#fde2e4",
  boardSide2: "#bee1e6",
  seedSide1: "#4e5d5c",
  seedSide2: "#4e5d5c",
  boardOrange: "#ef8d3982",
  boardGrey: "#525354",
  seedOrange: "#ef8d39",
  seedGrey: "#525354",
  activeColor: activeGreen,
  activeGreen,
  sowingDirection,
  sowingDirectionDarker,
  grey0: "#555",
  grey1: "#666",
  grey2: "#777",
  grey3: "#888",
  grey4: "#999",
  grey5: "#aaa",
  grey6: "#bbb",
  grey7: "#ccc",
  grey8: "#ddd",
  grey9: "#eee",
  grey95: "#efefef"
};
const theme = {
  gridUnit,
  boardWidth,
  boardHeight,
  iconSize,
  toolbarHeight,
  holeHeight,
  holeWidth,
  seedRadius: gridUnit * 1.5,
  colors,
  button: {
    borderRadius: gridUnit * 2,
    padding: gridUnit * 3,
    textDecoration: "none",
    background: colors.grey1,
    color: colors.grey95,
    border: "none",
    "&:hover": {
      background: colors.grey0,
      color: "white"
    }
  }
};
export default theme;