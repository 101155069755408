// @flow
import type { Element } from "React";
import type { Side } from "src/core/types.js";

type Props = {
  side: Side
};
const Seed = (props: Props): Element<"div"> => (
  <div
    css={(theme) => ({
      borderRadius: "100%",
      width: theme.gridUnit * 2.5,
      height: theme.gridUnit * 2.5,
      backgroundColor: props.side === 0 ? theme.colors.seedOrange : theme.colors.seedGrey,
      margin: 1
    })}
  />
);

export default Seed;
