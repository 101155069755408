//@flow
import type { Element } from "React";
import { useContext, useEffect, useState } from "react";
import { userPlay } from "../../core/baoside.js";
import Board from "../Board/Board.jsx";
import BoardPropertiesContext from "../../components/providers/BoardPropertiesProvider.jsx";
import { HelpInstruction } from "./HelpInstruction.jsx";
import { ReactComponent as Stop } from "../../icons/stop.svg";
import { ReactComponent as Play } from "../../icons/play.svg";

import type { GameState, MoveDirection } from "../../core/types.js";

type Props = {
  instruction: string,
  gameState: GameState,
  moveIndex: number,
  moveDirection: MoveDirection,
  highlightedHoles?: { "0": number[], "1": number[] }
};

const noop = () => {};

const playbackSpeed = 800;
const HelpPanel = (props: Props): Element<"div"> => {
  const currentPlayer = 1;
  const moveSequence = userPlay(props.moveIndex, props.moveDirection, currentPlayer, props.gameState);
  const [currentSequenceIndex, setCurrentSequenceIndex] = useState(-1);
  const [playing, setPlaying] = useState(false);
  const { boardProperties } = useContext(BoardPropertiesContext);

  const currentSequence = moveSequence[currentSequenceIndex];
  const gameState = currentSequence ? currentSequence.state : props.gameState;
  useEffect(() => {
    const timerId = setTimeout(() => {
      if (playing) {
        if (currentSequenceIndex < moveSequence.length - 1) {
          setCurrentSequenceIndex(currentSequenceIndex + 1);
        } else {
          setPlaying(false);
        }
      }
    }, playbackSpeed);

    return () => clearTimeout(timerId);
  }, [currentSequenceIndex, moveSequence.length, playing]);

  useEffect(() => {
    setCurrentSequenceIndex(-1);
  }, [props.instruction]);

  const SCALE_FACTOR = 2.5;
  const scaledBoardProperties = {
    ...boardProperties,
    boardWidth: boardProperties.boardWidth / SCALE_FACTOR,
    boardHeight: boardProperties.boardHeight / SCALE_FACTOR,
    holeHeight: boardProperties.holeHeight / SCALE_FACTOR,
    holeWidth: boardProperties.holeWidth / SCALE_FACTOR,
    highlightedHoles: props.highlightedHoles || boardProperties.highlightedHoles
  };
  return (
    <div css={{ display: "flex", background: "white", flexDirection: "column", alignItems: "center" }}>
      {moveSequence.length === 0 ? <Spacer/> : (
        <span
          css={(theme) => ({
            border: `3px solid`,
            borderRadius: "100%",
            marginBottom: theme.gridUnit * 2,
            display: "block",
            width: theme.gridUnit * 6,
            height: theme.gridUnit * 6,
            animation: "highlight 2s linear 0s infinite alternate"
          })}
          onClick={() => {
            setCurrentSequenceIndex(-1);
            setPlaying((playing) => !playing);
          }}
        >
          {playing ? <Stop /> : <Play />}
        </span>
      )}
      <BoardPropertiesContext.Provider
        value={{
          boardProperties: scaledBoardProperties,
          setBoardProperties: () => {}
        }}
      >
        <Board
          store={0}
          startingSide={0}
          dispatch={noop}
          currentPlayer={currentPlayer}
          activeSide={currentSequence ? currentSequence.side : currentPlayer}
          activeIndex={currentSequence ? currentSequence.index : -1}
          gameState={gameState}
          idle={!playing}
        />
      </BoardPropertiesContext.Provider>
      <HelpInstruction text={props.instruction} />
    </div>
  );
};

function Spacer() {
  return <div css={() => ({height: 38})}></div>
}
export default HelpPanel;
