//@flow
import * as React from "react";
import Side from "../Side";
import styles from "./Board.module.css";
import classnames from "classnames/bind";
import { KISWAHILI } from "src/core/baogame.js";
import { getFrontHoles, getRearHoles, getValidMoves } from "src/core/baoside";

import type { GameState, GameType, Side as GameSide } from "src/core/types";
import type { Props as SideProps } from "../Side/Side.jsx";
import type { Action } from "src/reducer.js";

type Props = {
  gameState: GameState,
  store: number,
  activeSide: GameSide,
  startingSide: GameSide,
  gameType?: GameType,
  currentPlayer: GameSide,
  idle?: boolean,
  activeIndex: number,
  className?: string,
  dispatch: (Action) => void,
  children?: React.Node
};

const cx = classnames.bind(styles);

const Board = (props: Props): React.Element<"div"> => {
  const [sideA, sideB] = props.gameState;
  const seedsInStore = props.store;

  let sideAStore, sideBStore;
  if (props.startingSide === 0) {
    sideAStore = Math.floor(seedsInStore);
    sideBStore = Math.ceil(seedsInStore);
  } else {
    sideBStore = Math.floor(seedsInStore);
    sideAStore = Math.ceil(seedsInStore);
  }
  const { dispatch, currentPlayer, activeSide, activeIndex } = props;
  const side0Props: SideProps = {
    rearHoles: getRearHoles(sideA),
    frontHoles: getFrontHoles(sideA),
    adjacentHoles: getFrontHoles(sideB),
    validMoves: currentPlayer === 0 ? getValidMoves(sideA, sideB) : [],
    side: 0,
    activeIndex: activeSide === 0 ? activeIndex : -1,
    isActive: currentPlayer === 0,
    dispatch,
    idle: props.idle,
    store: props.gameType === KISWAHILI ? sideAStore : undefined
  };

  const side1Props: SideProps = {
    rearHoles: getRearHoles(sideB),
    frontHoles: getFrontHoles(sideB),
    adjacentHoles: getFrontHoles(sideA),
    validMoves: currentPlayer === 1 ? getValidMoves(sideB, sideA) : [],
    side: 1,
    activeIndex: activeSide === 1 ? activeIndex : -1,
    isActive: currentPlayer === 1,
    dispatch,
    store: props.gameType === KISWAHILI ? sideBStore : undefined,
    idle: props.idle
  };

  return (
    <div className={props.className} css={{ position: "relative" }}>
      <Side {...side0Props} />
      <div className={cx("sideSeparator")} />
      <Side {...side1Props} />
      {props.children}
    </div>
  );
};

export default Board;
