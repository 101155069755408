//@flow
//
import * as React from "react";

const flex = {
  justifyContent: "space-between",
  width: 425,
  display: "flex",
  paddingTop: 10,
  marginBottom: 15
};
type Props = {
  children: React.Node
};
const RadioButtonGroup = (props: Props): React.Element<"div"> => {
  return <div css={flex}>{props.children}</div>;
};

export default RadioButtonGroup;
