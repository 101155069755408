//@flow
import type { Element } from "React";
import Icon from "src/icons/Icon";
import { ReactComponent as MailIcon } from "../../icons/mail.svg";
import { ReactComponent as TwitterIcon } from '../../icons/twitter.svg'
import theme from 'src/theme';

type Props = {
  path: string
};
const Contact = (props: Props): Element<"div"> => (
  <div
    css={(theme) => {
      let boardWidth = (theme.holeWidth + 16) * 8 + 10;
      let boardHeight = (theme.holeHeight + 16) * 4 + 10;
      return {
        width: boardWidth,
        height: boardHeight,
        fontSize: theme.gridUnit * 4,
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      };
    }}
  >
    <div>
      <Icon>
        <a href="mailto:bawoapp@dustygem.co.uk?subject=Feeback about bawo">
          <MailIcon />
        </a>
      </Icon>
    </div>

    <div>
      <Icon>
        <a href="https://twitter.com/dodopat">
          <TwitterIcon />
        </a>
      </Icon>
    </div>

    <a css={theme.button} href="/">
      Home
    </a>
  </div>
);

export default Contact;
