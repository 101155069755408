// @flow
import type { Element } from "React";
import Seed from "../../Seed";
import type { Side } from "src/core/types";

export type Props = {
  seeds: number,
  side: Side,
  holeWidth: number,
  holeHeight: number,
  isHighlighted?: boolean
};

const SimpleDomHole = (props: Props): Element<"div"> => (
  <div
    css={(theme) => {
      const background = props.side === 1 ? theme.colors.boardOrange : theme.colors.boardGrey;
      return {
        width: props.holeWidth,
        height: props.holeHeight,
        background,
        display: "flex",
        justifyContent: "center",
        alignContent: props.side === 1 ? "flex-start" : "flex-end",
        flexWrap: "wrap",
        padding: theme.gridUnit,
        marginBottom: theme.gridUnit / 2,
        borderRadius: theme.gridUnit,
        border: `2px solid ${props.isHighlighted ? "red" : background}`
      };
    }}
  >
    {Array(props.seeds)
      .fill(0)
      .map((d, i) => (
        <Seed side={props.side} key={`some-seed-${i}`} />
      ))}
  </div>
);

export default SimpleDomHole;
