// @flow
import * as d3 from "d3";
import { keyframes } from "@emotion/react";
import { memo, useRef, useEffect } from "react";

import type { Side } from "src/core/types";
import typeof Theme from "src/theme.js";

export type Props = {
  isHouse?: boolean,
  seedRadius?: number,
  seeds: number,
  showHint?: boolean,
  side: Side,
  holeWidth: number,
  holeHeight: number,
  isHighlighted?: boolean
};

export const width = 56;
export const height = 56;
type SeedPosition = {
  name: number,
  radius: number,
  x: number,
  y: number
};

export const generateSeedPositions = (
  numberOfSeeds: number,
  side: Side,
  width: number,
  height: number,
  existingData: SeedPosition[] = [],
  seedRadius?: number
): SeedPosition[] => {
  const seedsPerRow = 6;
  const radius = seedRadius ? seedRadius : width / (seedsPerRow * 2);
  const seedsList = Array(numberOfSeeds)
    .fill(0)
    .map((_, index) => {
      const oldSeed = existingData[index];
      const x = oldSeed ? oldSeed.x : radius + (index % seedsPerRow) * radius * 2;
      const y = oldSeed ? oldSeed.y : radius + Math.floor(index / seedsPerRow) * radius * 2;

      return {
        name: index,
        radius: oldSeed ? oldSeed.radius : radius,
        x,
        y: side === 1 ? y : height - y
      };
    });
  return seedsList;
};

const renderSeeds = (props: Props, group: ?Element) => {
  const { seeds, side } = props;
  const { holeWidth, holeHeight } = props;
  const existingData = d3.select(group).selectAll("circle").data();
  const seedPositions = generateSeedPositions(seeds, side, holeWidth, holeHeight, existingData, props.seedRadius);

  const simulation = d3
    .forceSimulation(seedPositions)
    .velocityDecay(0.3)
    .force("x", d3.forceX(holeWidth / 2).strength(0.1))
    .force("y", d3.forceY(holeHeight / 2).strength(0.1))
    .force("center", d3.forceCenter(holeWidth / 2, holeHeight / 2))
    .force(
      "collide",
      d3
        .forceCollide()
        .radius((d) => d.radius + 0.8)
        .iterations(2)
    )
    .on("tick", ticked);

  function ticked() {
    const svgSeeds = d3
      .select(group)
      .selectAll(`circle`)
      .data(seedPositions, (d) => d.name);

    svgSeeds.join(
      (enter) =>
        enter
          .append("circle")
          .attr("r", (d) => d.radius)
          .attr("cx", (d) => d.x)
          .attr("cy", (d) => d.y),
      (update) => update.attr("cx", (d) => d.x).attr("cy", (d) => d.y)
    );

    svgSeeds.exit().remove();
  }
  return simulation;
};


const PhysicsHole = (props: Props) => {
  const group = useRef(null);
  const simulation = useRef(null);

  useEffect(() => {
    simulation.current = renderSeeds(props, group.current);
  });

  const { showHint, isHouse, side, holeWidth, holeHeight, isHighlighted } = props;
  const svgStyle = (theme: Theme) => {
    const { boardSide1, boardSide2, seedSide1, seedSide2 } = theme.colors;
    const backgroundColor = side === 1 ? boardSide1 : boardSide2;
    const seedColor = side === 1 ? seedSide1 : seedSide2;
    const borderColor = isHighlighted ? "red" : d3.rgb(backgroundColor).darker(0.2);
    const backgroundHighlight = keyframes`
      from {
        background: ${backgroundColor};
      }
      to {
        background: white;
      }`;
    return {
      borderRadius: isHouse ? theme.gridUnit : "100%",
      padding: theme.gridUnit,
      background: backgroundColor,
      animation: isHighlighted
        ? "highlight 2s linear 0s infinite alternate"
        : showHint
        ? `${backgroundHighlight} 2s linear 0s infinite alternate`
        : null,
      border: `2px solid ${borderColor}`,
      circle: {
        fill: seedColor
      }
    };
  };
  return (
    <svg css={svgStyle} width={holeWidth} height={holeHeight}>
      <g ref={(node) => (group.current = node)} />
    </svg>
  );
};

const arePropsSame = (props: Props, previousProps: Props) =>
  props.seeds === previousProps.seeds &&
  props.isHouse === previousProps.isHouse &&
  props.holeHeight === previousProps.holeHeight &&
  props.holeWidth === previousProps.holeWidth &&
  props.showHint === previousProps.showHint &&
  props.isHighlighted === previousProps.isHighlighted;

const MemoizedHole: React$AbstractComponent<Props, mixed> = memo<Props>(
  (props: Props) => <PhysicsHole {...props} />,
  arePropsSame
);
export default MemoizedHole;
