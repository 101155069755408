//@flow
//
import type { Element } from "React";
import classnames from "classnames/bind";

import type { Side } from "../core/types";

import styles from "./Pointing.module.css";

type Props = {
  side: Side,
  currentActiveSide: Side
};

const cx = classnames.bind(styles);

const Pointing = (props: Props): Element<"svg"> => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 100 125"
    className={cx("hand", { rotate: props.currentActiveSide === 0 })}
  >
    <g>
      <path d="M91.238,60.696c-2.649-7.036-5.144-19.826-9.098-26.221C81.027,32.673,68.17,15.459,68.17,15.459l-0.014,0.009    c-0.616-1.002-1.568-1.801-2.78-2.169c-2.645-0.812-5.444,0.679-6.252,3.322c-0.412,1.348-0.223,2.733,0.407,3.877l-0.005,0.003    l0.794,1.638c0,0,4.896,7.872,5.216,8.457l-2.669,1.334c-0.239-0.422-9.645-14.932-9.645-14.932l-0.02,0.01    c-0.586-0.991-1.485-1.81-2.653-2.236c-2.667-0.971-5.613,0.405-6.583,3.07c-0.51,1.4-0.361,2.871,0.27,4.099l-0.007,0.005    l1.398,2.55c0,0,6.95,10.53,7.036,10.66l-2.686,1.344c-0.015-0.018-0.022-0.04-0.038-0.059L39.291,20.522    c-0.623-1.015-1.567-1.842-2.781-2.26c-2.446-0.842-5.946,0.931-6.762,3.3c-0.563,1.637-0.266,3.353,0.63,4.687l-0.022,0.015    l0.219,0.393l8.563,13.387l-2.671,1.335L15.347,8.357c0,0,1.186,1.963-0.008,0.003c-1.193-1.961-1.7-2.389-3.17-2.896    C9.328,4.488,6.229,5.998,5.25,8.841c-0.589,1.712-0.049,3.306,0.7,4.886c0.747,1.58-0.009,0.004-0.009,0.004l19.242,35.465    l1.663,3.076L37.397,69.2L31.9,66.088l-0.015-0.008c-0.321-0.242-9.317-8.033-11.516-9.758c-2.198-1.723-5.592-3.096-8.312-2.963    s-4.461,1.516-5.033,2.39c-0.574,0.875-0.89,2.244,0.186,3.382c0.801,0.848,12.438,13.107,12.438,13.107s2.355,2.008,2.817,2.388    l-0.001,0.004l14.4,8.667l5.525,2.983l17.931,8.552L95,78.516C95,78.516,92.743,64.696,91.238,60.696z" />
    </g>
  </svg>
);

export default Pointing;
