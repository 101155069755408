//@flow
import type { Element } from "React";

import { Link } from "@reach/router";

import { ReactComponent as HelpSVG } from "../../icons/help.svg";
import { ReactComponent as RestartSVG } from "../../icons/refresh.svg";
import { ReactComponent as SettingsSVG } from "../../icons/settings-gear.svg";
import { ReactComponent as GameSVG } from "../../icons/four_seeds.svg";
import { ReactComponent as HistorySVG } from "../../icons/history.svg";
import Icon from "src/icons/Icon.jsx";
const toolbarStyle = (theme) => ({
  display: "flex",
  justifyContent: "space-between",
  margin: `${theme.gridUnit * 5}px auto`,
  borderBottom: "4px solid black",
  width: theme.gridUnit * 65,
  ".active": {
    borderRadius: "5px 5px 0 0",
    display: "inline-block",
    background: "black",
    svg: { fill: "white" },
    div: { color: "white" }
  }
});
const isActive = ({ isCurrent, isPartiallyCurrent, href, location }) => {
  return isCurrent
    ? {
        className: "active"
      }
    : null;
};

type Props = {
  hash: string
};
const Toolbar = ({ hash }: Props): Element<"div"> => {
  return (
    <div css={toolbarStyle}>
      <Link to={`/game/settings`} getProps={isActive}>
        <Icon>
          <SettingsSVG />
          <div>settings</div>
        </Icon>
      </Link>
      <Link to="/game/history" getProps={isActive}>
        <Icon>
          <HistorySVG />
          <div>history</div>
        </Icon>
      </Link>
      <Link to={`/game/restart`} getProps={isActive}>
        <Icon>
          <RestartSVG />
          <div>restart</div>
        </Icon>
      </Link>
      <Link to={`/game`} getProps={isActive}>
        <Icon>
          <GameSVG />
          <div>game</div>
        </Icon>
      </Link>
      <Link to={`/game/help`} getProps={isActive}>
        <Icon>
          <HelpSVG />
          <div>help</div>
        </Icon>
      </Link>
    </div>
  );
};

export default Toolbar;
