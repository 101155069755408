//@flow
import * as React from "react";

export type BoardProperties = {
  holeWidth: number,
  holeHeight: number,
  boardWidth: number,
  boardHeight: number,
  highlightedHoles: {
    "0": number[],
    "1": number[]
  }
};
export type ContextType = {
  boardProperties: BoardProperties,
  setBoardProperties?: (BoardProperties) => void
};
export const initialBoardProperties = {
  holeWidth: 72,
  holeHeight: 56,
  boardWidth: 688,
  boardHeight: 284,
  highlightedHoles: {
    "0": ([]: Array<number>),
    "1": ([]: Array<number>)
  }
};
const initialContextValue = {
  boardProperties: initialBoardProperties
};
const BoardPropertiesContext: React$Context<ContextType> = React.createContext<ContextType>(initialContextValue);
export default BoardPropertiesContext;
