//@flow
import type { Element } from "React";
import { useContext, useEffect } from "react";
import { navigate } from "@reach/router";
import GameSettingsContext from "src/components/providers/GameSettingsProvider.jsx";
import type { Settings, ExpertLevel } from "src/core/types.js";
import RadioButton from "../RadioButton";
import RadioButtonGroup from "../RadioButtonGroup";
import { logPageView } from "src/hooks/use-analytics";
import { Helmet } from "react-helmet";

type Props = {
  path?: string
};

const noopUpdate = (d: Settings) => {};
const expertLevels = [
  { label: "Beginner", value: "beginner" },
  { label: "Intermediate", value: "intermediate" },
  { label: "Expert", value: "expert" }
];
const playbackSpeedLevels = [
  { label: "Fast", value: 400 },
  { label: "Slow", value: 550 },
  { label: "Super slow", value: 750 }
];
const SettingsForm = (props: Props): Element<"div"> => {
  const { settings, setGameSettings = noopUpdate } = useContext(GameSettingsContext);

  const onExperLevelChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const expertLevel: ExpertLevel = ((event.currentTarget.value: any): ExpertLevel);
    let updatedSettings = { ...settings, expertLevel };
    setGameSettings(updatedSettings);
  };
  const onPlaybackSpeedChange = (event: SyntheticEvent<HTMLInputElement>) => {
    let updatedSettings = { ...settings, playbackSpeed: +event.currentTarget.value };
    setGameSettings(updatedSettings);
  };
  useEffect(() => {
    logPageView("Bao | Game settings", window.location.href);
  });
  return (
    <div
      css={(theme) => {
        let boardWidth = (theme.holeWidth + 16) * 8 + 10;
        let boardHeight = (theme.holeHeight + 16) * 4 + 10;
        return {
          width: boardWidth,
          height: boardHeight,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          background: "white",
          color: theme.colors.grey5
        };
      }}
    >
      <Helmet>
        <title>Bawo | Game settings</title>
        <meta name="description" content="Game settings" />
      </Helmet>
      <div>
        <label htmlFor="expertLevel">Level</label>
        <br />
        <RadioButtonGroup>
          {expertLevels.map(({ label, value }) => (
            <RadioButton
              label={label}
              key={label}
              id={label}
              checked={settings.expertLevel === value}
              value={value}
              name="expertLevel"
              onChange={onExperLevelChange}
            />
          ))}
        </RadioButtonGroup>
      </div>
      <div>
        <label htmlFor="playbackSpeed">Speed</label>
        <RadioButtonGroup>
          {playbackSpeedLevels.map(({ label, value }) => (
            <RadioButton
              label={label}
              key={label}
              id={label}
              checked={settings.playbackSpeed === value}
              value={value}
              name="playbackSpeed"
              onChange={onPlaybackSpeedChange}
            />
          ))}
        </RadioButtonGroup>
      </div>
      <button
        css={(theme) => ({
          ...theme.button
        })}
        onClick={() => {
          navigate("/game");
        }}
      >
        Resume game
      </button>
    </div>
  );
};

export default SettingsForm;
