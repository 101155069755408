// @flow
import { useState, useEffect } from "react";
import * as React from "react";

import Backend from "./components/providers/DndBackend.js";
import { ThemeProvider } from "@emotion/react";
import defaultTheme from "./theme";

import { DndProvider } from "react-dnd";
import { Router } from "@reach/router";
import appStyles from "./App.module.css";
import OrientationContext, { LANDSCAPE, PORTRAIT } from "./components/providers/OrientationProvider.jsx";
import BoardPropertiesContext, { initialBoardProperties } from "./components/providers/BoardPropertiesProvider.jsx";

import BoardContainer from "./components/Board";
import Home from "./components/Home";
import Contact from './components/Contact';
import { CookieBanner } from "./components/CookieBanner/CookieBanner.jsx";

function App(): React.Node {
  const { innerWidth, innerHeight } = window;
  const [orientation, setOrientation] = useState(innerWidth < innerHeight ? PORTRAIT : LANDSCAPE);
  const [theme, setTheme] = useState(defaultTheme);
  const [boardProperties, setBoardProperties] = useState(initialBoardProperties);

  useEffect(() => {
    const onResize = () => {
      const { innerWidth, innerHeight, location } = window;
      const isOnHelpScreen = location.pathname.includes("/help");
      // update the variables for size
      const isPortrait = innerWidth < innerHeight;
      const [width, height] = isPortrait ? [innerHeight, innerWidth] : [innerWidth, innerHeight];
      const minBoardWidth = 600;
      const boardWidth = Math.min(minBoardWidth, width - 20);
      const boardHeight = Math.min(height, boardWidth / 2);
      const holeWidth = (boardWidth - 10) / 8 - 16;
      const holeHeight = (boardHeight - 10) / 4 - 16;
      const appElement = document.body;
      if (appElement) {
        appElement.style.setProperty("--holeHeight", `${holeHeight}px`);
        appElement.style.setProperty("--holeWidth", `${holeWidth}px`);
        appElement.style.setProperty("--boardHeight", `${boardHeight}px`);
        appElement.style.setProperty("--boardWidth", `${boardWidth}px`);
        if (isOnHelpScreen && isPortrait) {
          appElement.style.setProperty("--boardHeight", `${boardWidth}px`);
          appElement.style.setProperty("--boardWidth", `${boardHeight}px`);
        }
      }
      const properties = {
        holeWidth,
        holeHeight,
        boardWidth,
        boardHeight,
        highlightedHoles: { "0": [], "1": [] }
      };
      setOrientation(isPortrait ? PORTRAIT : LANDSCAPE);
      setBoardProperties(properties);
      setTheme({
        ...defaultTheme,
        holeHeight,
        holeWidth,
        boardHeight,
        boardWidth
      });
    };
    onResize();
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <DndProvider backend={Backend}>
        <OrientationContext.Provider value={orientation}>
          <BoardPropertiesContext.Provider value={{ setBoardProperties, boardProperties }}>
            <div className={appStyles.App}>
              <Router
                css={(theme) => ({
                  width: orientation === PORTRAIT ? theme.boardHeight : theme.boardWidth,
                  height: orientation === PORTRAIT ? theme.boardWidth : theme.boardHeight
                })}
              >
                <Home path="/" />
                <BoardContainer path="/game/*" />
                <Contact path="/contact"/>
              </Router>
              <CookieBanner/>
            </div>
          </BoardPropertiesContext.Provider>
        </OrientationContext.Provider>
      </DndProvider>
    </ThemeProvider>
  );
}

export default App;
