// @flow
import type { Element } from "React";

type Props = {
  size: number,
  activeIndex: number
};

const pillContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignSelf: "center",
  flex: "auto"
};
const ProgressPills = (props: Props): Element<"div"> => (
  <div css={pillContainerStyle}>
    {Array(props.size)
      .fill(0)
      .map((_, index) => (
        <div
          key={`key_${index}`}
          css={(theme) => ({
            borderRadius: "100%",
            margin: `0 ${theme.gridUnit}px`,
            border: `1px solid ${theme.colors.activeColor}`,
            background: index === props.activeIndex ? theme.colors.activeColor : "none",
            width: theme.gridUnit * 2,
            height: theme.gridUnit * 2
          })}
        />
      ))}
  </div>
);

export default ProgressPills;
