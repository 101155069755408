//@flow
import type { ExpertLevel } from "../core/types";

const { gtag  = (...args: any) => {
    console.warn(...args);
    console.warn("failed to load gtag")
}} = window;

type GameFinished = { expertLevel: ExpertLevel, numberOfTurns: number, gameType: string, startingSide: number };
type GameStarted = { expertLevel: ExpertLevel, gameType: string, startingSide: number };
export function logPageView(pageTitle: string, pageUrl: string, pageAttributes: any = {}) {
  gtag("config", "G-1QEZXPWG5V", {
    page_title: pageTitle,
    page_location: pageUrl,
    ...pageAttributes
  });
}

export function logGameStarted(attributes: GameStarted) {
  gtag("event", "game_started", attributes);
}

export function logGameWon(attributes: GameFinished) {
  gtag("event", "game_won", attributes);
}

export function logGameLost(attributes: GameFinished) {
  gtag("event", "game_lost", attributes);
}
export function logTutorialBegin() {
  gtag("event", "tutorial_begin");
}

export function logTutorialComplete() {
  gtag("event", "tutorial_complete");
}

export function logTutorialNavigatedForward(index: number) {
  gtag("event", "tutorial_navigated_forward", {
    help_index: index
  });
}

export function logTutorialNavigatedBackward(index: number) {
  gtag("event", "tutorial_navigated_backward", {
    help_index: index
  });
}