//@flow
import type { Element } from "React";

type SowingDirectionProps = {
  sowLeft: () => void,
  sowRight: () => void
};

const styles = (theme) => ({
  position: "absolute",
  display: "flex",
  alignItems: "center",
  width: "100%",
  height: "100%"
});
const leftArrowWrapperStyles = (theme) => ({
  position: "absolute",
  display: "flex",
  height: "95%",
  alignItems: "center",
  right: "55%",
  zIndex: 4,
  justifyContent: "flex-end",
  width: "100%",
  cursor: "pointer",
  borderRadius: "100% 0 0 5px",
  background: theme.colors.sowingDirection,
  "&:hover": {
    background: theme.colors.sowingDirectionDarker
  }
});
const rightArrowWrapperStyles = (theme) => ({
  ...leftArrowWrapperStyles(theme),
  right: undefined,
  borderRadius: "0 100% 5px 0",
  justifyContent: "flex-start",
  left: "55%"
});
const SowingDirection = (props: SowingDirectionProps): Element<"div"> => {
  return (
    <div css={styles}>
      <SowingDirectionLeft onClick={props.sowLeft} />
      <SowingDirectionRight onClick={props.sowRight} />
    </div>
  );
};

type SowingDirectionLeftProps = {
  onClick: (SyntheticEvent<HTMLDivElement>) => void
};
type SowingDirectionRightProps = SowingDirectionLeftProps;

export function SowingDirectionLeft(props: SowingDirectionLeftProps): Element<"div"> {
  return (
    <div onClick={props.onClick} css={leftArrowWrapperStyles}>
      ◀◀◀◀
    </div>
  );
}
export function SowingDirectionRight(props: SowingDirectionRightProps): Element<"div"> {
  return (
    <div onClick={props.onClick} css={rightArrowWrapperStyles}>
      ▶▶▶▶
    </div>
  );
}
export default SowingDirection;
