//@flow
import * as React from "react";
import type { Side } from "../../core/types";

type SelectedHole = {
  side: Side,
  index: number,
  storeClicked?: boolean
};
const SelectedHoleContext: React$Context<?SelectedHole> = React.createContext<?SelectedHole>(undefined);
export default SelectedHoleContext;
