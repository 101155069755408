//@flow

import * as React from "react";

type Props = {
  children: React.Node,
  onClick?: () => void
};

const Icon = (props: Props): React.Element<"span"> => (
  <span
    onClick={props.onClick}
    css={({ iconSize, gridUnit, colors }) => ({
      padding: gridUnit,
      display: "inline-block",
      borderRadius: `${gridUnit}px ${gridUnit}px 0 0`,
      svg: {
        width: iconSize,
        height: iconSize,
        fill: colors.grey2
      },
      div: {
        fontSize: gridUnit * 3,
        color: colors.grey4,
        textAlign: "center"
      }
    })}
  >
    {props.children}
  </span>
);

export default Icon;
