//@flow

import type { Element } from "React";
import { useContext } from "react";
import { useDrag } from "react-dnd";
import SelectedHoleContext from "src/components/providers/SelectedHoleProvider.jsx";
import SimpleDomHole from "./SimpleDomHole";
import type { Side } from "../../core/types.js";
import type { UserClickStoreAction } from "src/reducer.js";
type Props = {
  seeds: number,
  side: Side,
  dispatch?: (UserClickStoreAction) => void
};

const collect = (monitor) => {
  return {
    isDragging: !!monitor.isDragging()
  };
};

//const dropCollector = monitor => ({
//  isOver: !!monitor.isOver(),
//  isOverCurrent: !!monitor.isOver({ shallow: true }),
//  canDrop: !!monitor.canDrop()
//});

const StoreHole = (props: Props): Element<"div"> => {
  const selectedHole = useContext(SelectedHoleContext);
  const [, drag] = useDrag({
    type: "Store",
    item: {
      side: props.side,
      seeds: 1,
      playableDirections: []
    },
    collect,
    canDrag: (monitor) => props.seeds > 0
  });

  return (
    <div css={{ display: "flex", justifyContent: "center" }}>
      <div
        ref={drag}
        onClick={() => {
          props.dispatch &&
            props.dispatch({
              type: "USER_CLICK_STORE",
              side: props.side
            });
        }}
        css={(theme) => ({
          backgroundColor:
            selectedHole && selectedHole.storeClicked && selectedHole.side === props.side ? theme.colors.grey7 : "white"
        })}
      >
        <SimpleDomHole holeWidth={200} holeHeight={25} side={props.side} seeds={props.seeds} />
      </div>
    </div>
  );
};
export default StoreHole;
