// @flow
import * as React from "react";
import { useState, useContext } from "react";
import Hole from "./PhysicsHole";
import Pointing from "../../icons/Pointing.jsx";
import typeof Theme from "../../theme.js";

import SelectedHoleContext from "../providers/SelectedHoleProvider";
import BoardPropertiesContext from "../../components/providers/BoardPropertiesProvider.jsx";
import GameSettingsContext from "../../components/providers/GameSettingsProvider.jsx";
import SowingDirection from "../SowingDirection";
import { useDrag, useDrop } from "react-dnd";
import { MTAJI, KISWAHILI } from "../../core/baogame.js";
import { COMPUTER_SIDE, getDirection, normaliseIndex } from "../../core/baoside";
import type { Side, MoveDirection } from "../../core/types";

const collect = (monitor) => {
  return {
    isDragging: !!monitor.isDragging()
  };
};

const dropCollector = (monitor) => ({
  isOver: !!monitor.isOver(),
  isOverCurrent: !!monitor.isOver({ shallow: true }),
  canDrop: !!monitor.canDrop()
});

type SowingDirectionHandler = {
  sowRight: () => void,
  sowLeft: () => void
};
export type Props = {
  seeds: number,
  adjacentSeeds?: number,
  index: number,
  isActive?: boolean,
  currentActiveSide: Side,
  side: Side,
  playableDirections: MoveDirection[],
  isValidMove: boolean,
  showHint: boolean,
  isHighlighted?: boolean,
  setNeedsCaptureSowingDirection: (?SowingDirectionHandler) => void,
  dispatch: (*) => void,
  children?: React.Node
};

const DraggableHole = (props: Props): React.Element<"div"> => {
  const selectedHole = useContext(SelectedHoleContext);
  const { boardProperties } = useContext(BoardPropertiesContext);
  const { gameStage, gameType } = useContext(GameSettingsContext);

  const [needsSowingDirection, setNeedsSowingDirection] = useState(false);

  const [{ isDragging }, drag] = useDrag({
    type: "Hole",
    item: {
      index: props.index,
      side: props.side,
      seeds: props.seeds,
      playableDirections: props.playableDirections
    },
    collect,
    canDrag: (monitor) => gameStage === MTAJI && props.isValidMove
  });

  const userPlay = (direction) => {
    props.dispatch({
      type: "USER_PLAY",
      index: props.index,
      side: props.side,
      direction
    });
  };

  const sowRight = () => {
    setNeedsSowingDirection(false);
    userPlay(1);
  };

  const sowLeft = () => {
    setNeedsSowingDirection(false);
    userPlay(-1);
  };

  function handleNamuaTurn() {
    if (props.adjacentSeeds) {
      if (props.index < 2 || props.index > 5) {
        // if we are at a kimbi and there is something to capture
        // we derive the direction based on the kimbi location
        const direction = props.index < 2 ? 1 : -1;
        return userPlay(direction);
      }
      return props.setNeedsCaptureSowingDirection({
        sowLeft,
        sowRight
      });
    }
    setNeedsSowingDirection(true);
  }

  const [{ canDrop }, drop] = useDrop({
    accept: ["Store", "Hole"],
    collect: dropCollector,
    drop: (item, monitor) => {
      if (monitor.didDrop()) {
        return;
      }
      const draggedItem = monitor.getItem();
      if (draggedItem.type === "Store") {
        return handleNamuaTurn();
      }
      props.dispatch({
        type: "USER_PLAY",
        index: draggedItem.index,
        side: draggedItem.side,
        direction: getDirection(draggedItem.index, props.index)
      });
      return monitor.getItem();
    },
    canDrop: (item, monitor) => {
      const draggedHole = monitor.getItem();
      return (
        (draggedHole.type === "Store" && props.isValidMove) ||
        (draggedHole.side === props.side &&
          ((props.index === normaliseIndex(draggedHole.index - 1) && draggedHole.playableDirections.includes(-1)) ||
            (props.index === normaliseIndex(draggedHole.index + 1) && draggedHole.playableDirections.includes(1))))
      );
    }
  });
  const isSelected = selectedHole && selectedHole.index === props.index && selectedHole.side === props.side;

  const handleHoleClick = () => {
    if (props.isValidMove && !needsSowingDirection && selectedHole && selectedHole.storeClicked) {
      return handleNamuaTurn();
    }
    props.dispatch({
      type: "USER_CLICK_HOLE",
      index: props.index,
      side: props.side
    });
  };
  const { side, showHint, isValidMove } = props;
  const { holeWidth, holeHeight } = boardProperties;

  const isHouse = gameType === KISWAHILI && props.index === 4;
  const dragContainerStyle = (theme: Theme) => {
    const backgroundColor = canDrop
      ? theme.colors.grey1
      : isDragging
      ? theme.colors.grey8
      : isSelected
      ? theme.colors.grey4
      : "white";
    const cursor =
      isDragging || (gameStage === MTAJI && isValidMove) || (selectedHole && selectedHole.storeClicked && isValidMove)
        ? "grab"
        : "not-allowed";
    return {
      position: "relative",
      borderRadius: isHouse ? theme.gridUnit : "100%",
      margin: `0 ${0.5 * theme.gridUnit}px`,
      cursor,
      backgroundColor
    };
  };

  return (
    <div
      ref={drag}
      css={(theme) => ({
        borderRadius: isHouse ? theme.gridUnit : "100%",
        backgroundColor: isSelected ? theme.colors.grey9 : "white"
      })}
      onClick={handleHoleClick}
    >
      <div ref={drop} css={dragContainerStyle}>
        {needsSowingDirection && <SowingDirection sowRight={sowRight} sowLeft={sowLeft} />}
        {props.children}
        <Hole
          side={props.side}
          isHouse={isHouse}
          index={props.index}
          seeds={isDragging ? 0 : props.seeds}
          holeWidth={holeWidth}
          holeHeight={holeHeight}
          showHint={showHint && side !== COMPUTER_SIDE && isValidMove}
          isHighlighted={props.isHighlighted}
        />
        {props.isActive && <Pointing side={props.side} currentActiveSide={props.currentActiveSide} />}
      </div>
    </div>
  );
};

export default DraggableHole;
